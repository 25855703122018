import { HStack, Slider, SliderThumb, SliderTrack, Text, VStack } from "@chakra-ui/react"
import Emphasis from "components/emphasis"
import Muted from "components/muted"
import { displayUnit, parseMeasurement, useDisabled } from "pages/util"
import { useState } from "react"
import { AutomationInput, Measurement, SaveAutomation } from "types"
import { useSkipFirstRender } from "./dwelling-automations"
import EmptyState from "./empty-state"
import useIsDisabled from "./useIsDisabled"

const min = (measurement: Measurement, unit: 'celsius' | 'fahrenheit', rising?: boolean) => {
    switch (measurement) {
        case 'humidity': {
            return rising ? 30 : 10
        }
        case 'dew_point': return unit === 'celsius' ? 0 : 32
    }
}

const max = (measurement: Measurement, unit: 'celsius' | 'fahrenheit', rising?: boolean) => {
    switch (measurement) {
        case 'humidity': {
            return rising ? 90 : 60
        }
        case 'dew_point': return unit === 'celsius' ? 25 : 72
    }
}

// input control
// get ride of override
const Humidity = ({ input, automationId, loading, set }: {
    input?: AutomationInput,
    automationId: number
    loading: boolean,
    set: any
    // save: SaveAutomation,
}) => {
    const startPoint = input?.start_set_point || 52
    const [sliderValue, setSliderValue] = useState(startPoint)
    const disabled = useIsDisabled(automationId)
    const disabledColor = useDisabled()

    const setter = (inputs) => {
        if (!input) return inputs

        return inputs.map(_input => {
            if (input.id === _input.id) {
                return {
                    ..._input,
                    start_set_point: sliderValue,
                }
            }
            return _input
        })
    }

    // const saveStartPoint = async (start_set_point: number) => {
    //     if (!input) return
    //     try {
    //         await save(prev => ({
    //             inputs: prev.inputs.map(_input => {
    //                 if (input.id === _input.id) {
    //                     return {
    //                         ..._input,
    //                         start_set_point,
    //                     }
    //                 }
    //                 return _input
    //             })
    //         }))
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }

    // const debounceSave = useRef(debounce(saveStartPoint, 1000)).current

    useSkipFirstRender(() => {
        if ((sliderValue !== 0) && !sliderValue) return // slider is falsey but not zero. zero could be valid
        if (sliderValue === input?.start_set_point) return // there has been no change
        set(setter)
        // debounceSave(sliderValue)
    }, [sliderValue])

    if (!input) return (
        <EmptyState title='Humidity' />
    )

    const measurement = parseMeasurement(input.measurement)
    const triggerAction = input.rising ? 'rises above' : 'drops below'

    return (
        <VStack
            spacing={8}
            alignItems='flex-start'
            w='100%'>
            <Muted>Activate equipment when <Emphasis>{measurement}</Emphasis> {triggerAction}</Muted>
            <HStack
                w='100%'
                justifyContent='space-between'
                alignItems='center'>
                <Slider
                    isDisabled={loading || disabled}
                    aria-label={input.measurement}
                    defaultValue={startPoint}
                    min={min(input.measurement, input.set_point_unit)}
                    max={max(input.measurement, input.set_point_unit)}
                    onChange={setSliderValue}
                >
                    <SliderTrack />
                    <SliderThumb />
                </Slider>
                <Text
                    {...(disabled ? { color: disabledColor } : {})}
                    textAlign='right'
                    width='64px'>{sliderValue}{displayUnit(input.set_point_unit || input.measurement)}</Text>
            </HStack>
        </VStack>
    )
}

export default Humidity
