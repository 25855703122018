import {
    Box,
    Card,
    HStack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react"
import { AutomationInput, AutomationOutput } from "types"
import { useMuted } from "pages/util"
import { useEffect, useMemo, useState } from "react"
import { useWindowSize } from "@react-hook/window-size"
import { useNavigate } from "react-router-dom"
import { fieldToLabel, capitalize } from "utils/text-transform"
import useAutomationsStore, { selectAutomation } from "./automationsStore"
import Subtitle from "./subtitle"


const itemWidth = 180

const color = type => {
    switch (type) {
        case 'input': return 'blue.500'
        case 'rule': return 'green.500'
        case 'output': return 'purple.500'
    }
}

const Detail = props => {
    const color = useMuted()
    return <Text fontSize='12px' color={color}  {...props} />
}

const Semibold = props => {
    return <Text as='span' fontWeight='semibold' {...props} />
}

function Item({ id, item, type }, ref) {


    const inputBorderColor = useColorModeValue('blue.200', 'blue.700')
    const ruleBorderColor = useColorModeValue('green.200', 'green.700')
    const outputBorderColor = useColorModeValue('purple.200', 'purple.700')

    const inputHoverColor = useColorModeValue('blue.50', 'blue.800')
    const outputHoverColro = useColorModeValue('purple.50', 'purple.800')
    const ruleHoverColor = useColorModeValue('green.50', 'green.800')

    const navigate = useNavigate()

    const hoverColor = type => {
        switch (type) {
            case 'input': return inputHoverColor
            case 'rule': return ruleHoverColor
            case 'output': return outputHoverColro
        }
    }

    const borderColor = type => {
        switch (type) {
            case 'input': return inputBorderColor
            case 'rule': return ruleBorderColor
            case 'output': return outputBorderColor
        }
    }
    const componentString = (component) => {
        switch (component) {
            case 0: return 'Compressor'
            case 1: return 'Fan'

        }
    }


    const ItemLink = (key) => {
        const link_type = key === 'device_id' ? 'devices' : 'equipments'
        return `/fleet/${link_type}/${item[key]}`

    }

    const mapDetails = (key) => {
        switch (key) {
            case 'equipment_component':
                if (item['equipment_type'] === 'ventilating_dehumidifier') {

                    return <Detail >{fieldToLabel(key)}: <Semibold>{`${componentString(item[key])}`}</Semibold></Detail>
                }
                return ""
            case 'equipment_id':
            case 'device_id':
                return <a href={ItemLink(key)} target={'_blank'}> <Detail _hover={{ backgroundColor: useMuted, color: borderColor(type) }}>{fieldToLabel(key)}: <Semibold>{`${item[key]}`}</Semibold></Detail ></a>
            default:
                return <Detail>{fieldToLabel(key)}: <Semibold>{`${item[key]}`}</Semibold></Detail>
        }

    }

    const renderDetail = () => {
        if (!item) return null
        return (

            <VStack
                textAlign='left'
                alignItems='flex-start'
                pb={2}
                spacing={0}
            >

                {
                    Object.keys(item)
                        .filter(x => x !== 'id')
                        .map(key => mapDetails(key))

                }
            </VStack>
        )
    }


    return (
        <Box
            data-id={id}
            px={2}
            width='100%'
            minWidth={'max-content'}
            borderRadius='md'
            borderWidth='1px'
            borderColor={borderColor(type)}
            _hover={{ bg: hoverColor(type), boxShadow: 'md' }}

        >
            <Text
                fontWeight={800}
                color={color(type)}
            >{capitalize(type)} {item.id}</Text>
            {renderDetail()}
        </Box>
    )
}

const selector = dataId => `[data-id="${dataId}"]`

const Relationships = ({ automationId, devices, equipment }) => {
    const automation = useAutomationsStore(selectAutomation(automationId))
    const borderColor = useColorModeValue('gray.200', 'gray.600')





    /**
     * this is bad but it's a hack to run another render
     * after the DOM is painted so the input and output
     * lines can be drawn. The lines need to wait for the
     * DOM to be ready because they directly manipulate
     * the DOM.
     * 
     * in a v2 the line elements should interact with
     * react refs, not directly with the DOM
     */
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (ready) return
        setReady(true)
    }, [automation?.rules?.length])

    const {
        inputs,
        rules,
        outputs,
    } = automation

    const inputLines = useMemo(() => {


        return automation.rules.map((rule, index) => {
            const ruleEl = document.querySelector(selector(`${automationId}-rule-${rule.id}`))
            if (!ruleEl) return
            const stopPoint = ruleEl?.getBoundingClientRect()

            return rule.inputs.map((input, index) => {
                const sel = `${automationId}-input-${input}`
                const element = document.querySelector(selector(sel))
                const parent = document.getElementById(`${automationId}-relationships-container`)?.getBoundingClientRect()
                const startPoint = element?.getBoundingClientRect()
                console.log(startPoint)
                if (!startPoint || !parent) return
                const startX = Math.abs(startPoint.x - parent.x + startPoint.width)
                const startY = Math.abs(startPoint.y - parent.y + 12)
                const endX = Math.abs(stopPoint.x - parent.x)
                const endY = Math.abs(stopPoint.y - parent.y + 12)



                const length = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2)
                const angle = (Math.atan2(endY - startY, endX - startX) * 180) / Math.PI

                return (
                    <Box
                        key={`${sel}${rule.id}`}
                        transform={`rotate(${angle}deg)`}
                        transformOrigin='top left'
                        position='absolute'
                        bg={borderColor}
                        left={`${startX}px`}
                        top={`${startY}px`}
                        w={`${length}px`}
                        h='1px'
                    />
                )
            })
        }).flatMap(x => x)
    }, [ready, automation.rules.length, useWindowSize()])

    const outputLines = useMemo(() => {
        return automation.rules.map(rule => {
            const ruleEl = document.querySelector(selector(`${automationId}-rule-${rule.id}`))
            if (!ruleEl) return
            const startPoint = ruleEl?.getBoundingClientRect()

            return rule.outputs.map((output, index) => {
                const sel = `${automationId}-output-${output}`
                const element = document.querySelector(selector(sel))
                const parent = document.getElementById(`${automationId}-relationships-container`)?.getBoundingClientRect()
                const stopPoint = element?.getBoundingClientRect()
                if (!stopPoint || !parent) return

                const startX = Math.abs(startPoint.x - parent.x + startPoint.width)
                const startY = Math.abs(startPoint.y - parent.y + 12)
                const endX = Math.abs(stopPoint.x - parent.x)
                const endY = Math.abs(stopPoint.y - parent.y + 12)

                const length = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2)
                const angle = (Math.atan2(endY - startY, endX - startX) * 180) / Math.PI

                return (
                    <Box
                        key={`${sel}${rule.id}`}
                        transform={`rotate(${angle}deg)`}
                        transformOrigin='top left'
                        position='absolute'
                        bg={borderColor}
                        left={`${startX}px`}
                        top={`${startY}px`}
                        w={`${length}px`}
                        h='1px'
                    />
                )
            })
        }).flatMap(x => x)
    }, [ready, automation.rules.length, useWindowSize()])

    const renderHeader = () => (
        <HStack

            justifyContent='space-between'
            alignItems='center'
        >
            <Subtitle>Relationships</Subtitle>

        </HStack>

    )
    return (
        <Card
            align='flex-start'
            p={6}
            flex={[
                '1 1 calc(100% - 16px)',
                '1 1 calc(100% - 16px)',
                '1 1 calc(50% - 16px)',
            ]}
        >
            <VStack
                w='100%'
                spacing={6}
                alignItems='flex-start'
            >
                {renderHeader()}
                <HStack
                    w='100%'
                    position='relative'
                    id={`${automationId}-relationships-container`}
                    justifyContent='space-between'>
                    <VStack
                        spacing={0}
                        w='25%'
                    >
                        {inputs.map(item => {
                            const device = devices.data.find(x => {
                                if (x.id === (item as AutomationInput).device_id) {
                                    return x.id
                                }
                                return null
                            })
                            let input_params = { ...item }
                            if (device != null) {
                                input_params = { ...input_params, ...{ hub_device_id: device.hub_device_id, plastic_serial_number: device.plastic_serial_number } }
                            }

                            return (<Item
                                id={`${automationId}-input-${item.id}`}
                                key={item.id}
                                type='input'
                                item={input_params} />)
                        })}
                    </VStack>
                    <VStack
                        spacing={0}
                        w='25%'
                    >
                        {rules.map((item, index) =>
                            <Item
                                id={`${automationId}-rule-${item.id}`}
                                key={item.id}
                                type='rule'
                                item={item} />)}
                    </VStack>
                    <VStack
                        spacing={0}
                        w='25%'

                    >
                        {outputs.map(item => {


                            const device = devices.data.find(x => {
                                if (x.id === (item as AutomationOutput).device_id) {
                                    return x.id
                                }
                                return null
                            })
                            const equip = equipment.find(x => {
                                if (x.id === (item as AutomationOutput).equipment_id) {
                                    return x.id
                                }
                                return null
                            })
                            let output_params = { ...item }
                            if (device != null) {
                                output_params = { ...output_params, ...{ hub_device_id: device.hub_device_id, plastic_serial_number: device.plastic_serial_number } }
                            }
                            if (equip != null) {
                                output_params = { ...output_params, ...{ equipment_type: equip.type, equipment_name: equip.name } }
                            }

                            return <Item
                                id={`${automationId}-output-${item.id}`}
                                key={item.id}
                                type='output'
                                item={output_params} />
                        })}
                    </VStack>
                    {inputLines}
                    {outputLines}
                </HStack>
            </VStack>
        </Card>
    )
}

export default Relationships
