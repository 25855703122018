import "ag-grid-community/dist/styles/ag-grid.css";

import { Grid, useMediaQuery } from "@chakra-ui/react";
import { PermissionsDescription, RolesDescription } from "../../../components/info-popup";
import React, { useEffect, useState } from "react";

import Card from "../../../components/card";
import DevicesList from "components/devices-list";
import EntitiesTable from "../../../components/entities-list";
import Permissions from "../../../components/permissions";
import Roles from "../../../components/roles";
import { UserDetails } from "./user-details";
import { UserProfile } from "./user-profile";

export default function User({ user }) {
    const { details, profile, permissions, roles, devices, filters, ecosense = null, refreshPermissions } = user;

    const [isLargeScreen] = useMediaQuery("(min-width: 1480px)");
    const [isSmallScreen] = useMediaQuery("(max-width: 850px)");
    const [columnCount, setColumnCount] = useState(3);

    useEffect(() => {
        if (isSmallScreen) {
            setColumnCount(1);
        } else if (isLargeScreen) {
            setColumnCount(3);
        } else {
            setColumnCount(2);
        }
    }, [columnCount, isLargeScreen, isSmallScreen]);
    console.log("user eco", ecosense)
    return (
        <Grid templateColumns={`repeat(${columnCount}, 1fr)`} gap={4} width="100%">
            <DetailsCard details={details} />
            <ProfileCard profile={profile} />
            {devices?.data && ecosense?.data && <DevicesCard devices={devices} ecosense={ecosense} />}
            {/* TODO: figure out a better way to do this than checking 'details?.data?.id'. TODO: do it everywhere in the app where checking for 'details?.data?.id' anywhere else. */}
            {details?.data?.id && <RolesCard roles={roles} userId={+details?.data?.id} refreshPermissions={refreshPermissions} />}
            {details?.data?.id && <PermissionsCard permissions={permissions} userId={+details?.data?.id} />}
            <FiltersCard filters={filters} />
        </Grid>
    );
}

function DetailsCard({ details }) {
    return (
        <Card header="Details" error={details.error} loading={details.loading}>
            {details.data && <UserDetails details={details.data}></UserDetails>}
        </Card>
    );
}

function ProfileCard({ profile }) {
    return (
        <Card header="Profile" error={profile.error} loading={profile.loading}>
            {profile.data && <UserProfile profile={profile.data}></UserProfile>}
        </Card>
    );
}

function DevicesCard({ devices, ecosense = null }) {
    return (
        <Card header="Devices" error={devices.error} loading={devices.loading}>
            {devices?.data && <DevicesList devices={devices.data} ecosense={ecosense?.data?.devices}></DevicesList>}
        </Card>
    );
}

function RolesCard({ roles, userId, refreshPermissions }) {
    function onRoleChange() {
        refreshPermissions();
    }

    return (
        <Card header="Company Roles" error={roles.error} loading={roles.loading || userId === null} descriptionPopup={<RolesDescription />}>
            {roles.data && <Roles roles={roles.data} id={userId} parentEntity={"user"} onChange={onRoleChange}></Roles>}
        </Card>
    );
}
function PermissionsCard({ permissions, userId }) {
    return (
        <Card
            header="Dwellings Permissions"
            error={permissions.error}
            loading={permissions.loading || userId === null}
            descriptionPopup={<PermissionsDescription />}
        >
            {permissions.data && (
                <Permissions permissions={permissions.data} id={userId} parentEntity={"user"} refreshFlag={true}></Permissions>
            )}
        </Card>
    );
}
function FiltersCard({ filters }) {
    return (
        <Card header="Filters" error={filters.error} loading={filters.loading}>
            {filters.data && <EntitiesTable list={filters.data} openUrl="filters" columns={["id", "rating", "type"]}></EntitiesTable>}
        </Card>
    );
}

