import { Heading, Text } from "@chakra-ui/react";
import { NumberInput, SelectInput } from "components/inputs";
import React, { useState } from "react";

import EntitiesList from "components/entities-list";
import { FormGroup } from "components/form";
import { HavenGreenButton } from "components/buttons";
import { getDeviceOffsets } from "api/api";
import { useForm } from "react-hook-form";
import { usePost } from "api/api-hooks";
import InputLabel from "components/input-label";
import { MonitorOffsetInstructions } from "components/info-popup";

export function MonitorOffsets({ id, offsets }) {
    const [allOffsets, setAllOffsets] = useState(offsets.data);

    return (
        <>
            <SetOffsetForm id={id} setAllOffsets={setAllOffsets}></SetOffsetForm>
            <br></br>
            <Heading size="md"> History</Heading>
            {offsets.error && <Text>No previous offsets found</Text>}
            {allOffsets && (
                <EntitiesList
                    list={allOffsets}
                    columns={["timestamp", "temperature", "temperature_unit", "humidity"]}
                ></EntitiesList>
            )}
        </>
    );
}

function SetOffsetForm({ id, setAllOffsets }) {
    const { call } = usePost(`device/${id}/cam_offset`);
    const [badFormValues, setBadFormValues] = useState(false);
    const form = useForm({
        defaultValues: {
            monitor_temperature: 0,
            reference_temperature: 0,
            temperature_unit: "celsius", //"fahrenheit"
            monitor_humidity: 0,
            reference_humidity: 0,
            monitor_values_are_offset: false,
        },
    });
    // post a new offset and on success get the list of most recent offsets from the server
    function onClick() {

        const form_state = form.formState;
        let errorCount = 0;
        for (let key in form_state.errors) {
            errorCount += 1;
        }
        console.log("error count", errorCount)
        console.log(form.getValues())
        if (errorCount === 0) {
            call(form.getValues(), result => {
                getDeviceOffsets(id).then(offsets => setAllOffsets(offsets));

            });
            setBadFormValues(false);
        }
        else {
            setBadFormValues(true);
        }





    }

    function onReset() {
        const new_values = form.getValues();
        new_values.monitor_values_are_offset = true;
        call(new_values, result => {
            getDeviceOffsets(id).then(offsets => setAllOffsets(offsets));

        });


    }

    return (
        <>

            <FormGroup>
                <InputLabel>Monitor Readings or Absolute Offset <MonitorOffsetInstructions /></InputLabel>
                <NumberInput form={form} field="monitor_temperature" min={-100} max={100} step={5} required={true} ></NumberInput>
                {form.formState.errors.monitor_temperature && <Text color="red">Monitor temperature is required</Text>}
                <NumberInput form={form} field="monitor_humidity" min={-100} max={100} step={5} required={true}></NumberInput>
                {form.formState.errors.monitor_humidity && <Text color="red">Monitor humidity is required</Text>}
                <InputLabel>Reference Readings</InputLabel>
                <NumberInput form={form} field="reference_temperature" min={-100} max={100} step={5} required={true}></NumberInput>
                {form.formState.errors.reference_temperature && <Text color="red">Reference temperature is required</Text>}
                <NumberInput form={form} field="reference_humidity" min={-100} max={100} step={5} required={true}></NumberInput>
                {form.formState.errors.reference_humidity && <Text color="red">Reference humidity is required</Text>}
                <InputLabel>Temperature Unit</InputLabel>
                <SelectInput form={form} field="temperature_unit" options={["celsius", "fahrenheit"]}></SelectInput>
                <HavenGreenButton label="Adjust current offset" onClick={onClick}></HavenGreenButton>
                {badFormValues && <Text color="red">Please fill out all fields</Text>}
                <HavenGreenButton label="Set New Offset" onClick={onReset}></HavenGreenButton>
            </FormGroup >
        </>
    );
}
