import React, { useCallback, useEffect, useState } from "react";
import {
    apiUrl,
    fetchDelete,
    fetchPost,
    getAutomationDetails,
    getCompany,
    getCompanyDwellings,
    getCompanyInvites,
    getCompanyProfile,
    getCompanyRoles,
    getCompanyJourney,
    getCompanyJourneyLog,
    getCompanyQuestionnaire,
    getDevice,
    getDeviceAutomations,
    getDeviceChannels,
    getDeviceCurrentTelemetry,
    getDeviceHardwareState,
    getDeviceHavenScore,
    getDeviceOffsets,
    getDeviceTwin,
    getDwelling,
    getDwellingContents,
    getDwellingPermissions,
    getDwellingProfile,
    getEquipment,
    getFilter,
    getUser,
    getUserPermissions,
    getUserProfile,
    getUserRoles,
    getEcosenseDevices,
} from "./api";

import date from "date-and-time";
import splitInterval from "../utils/split-interval";
import { useToast } from "@chakra-ui/react";
import { de } from "date-fns/locale";

/**
 * Wrapper that calls useUpdate with POST header. It can be used interchangably with useUpdate but for consistency using usePost is preferred.
 * @param {string} url - API url
 */
export function usePost(url) {
    return useUpdate(url);
}

/**
 * Wrapper that calls useUpdate with DELETE header.
 * @param {string} url - API url
 */
export function useDelete(url) {
    return useUpdate(url, true);
}

/**
 * Generic update hook for performing updates across the app. This abstracts creating UI loading and success/fail notifications Used for both POST and DELETE requests since the only difference between them is the header.
 * @param {string} url - API url
 * @param {boolean} isDelete - whether to do a POST or DELETE request, POST by default
 */
export function useUpdate(url, isDelete = false) {
    // Loading state is used to communicate to the caller that the request is pending
    const [loading, setLoading] = useState(false);

    // Use toast popup to communicated the loading/success/failure status. This is way easier than implementing loading animation locally wherever update is called.
    const toast = useToast();
    const toastIdRef = React.useRef();

    // Use fetchPost by default, use fetchDelete if specified in the argument
    const fetchUpdate = isDelete ? fetchDelete : fetchPost;

    // Making a request requires the body parameter. Optionally, onSuccess and onError functions can be passed that would be called once the request returns the result.
    const call = useCallback(
        async (body, onSuccess = null, onError = null) => {
            if (!url) return

            // Set loading status and create a toast indicating loading status.
            setLoading(true);
            toastIdRef.current = toast({ description: "loading...", position: "bottom-right", duration: null });

            try {
                let result = await fetchUpdate(`${apiUrl}/${url}`, body);
                toast.update(toastIdRef.current, { title: "success", status: "success", isClosable: true, duration: 5000 });
                if (onSuccess) {
                    onSuccess(result);
                }
            } catch (e) {
                toast.update(toastIdRef.current, { title: e.message, status: "error", isClosable: true, duration: 5000 });
                if (onError != null) {
                    onError(e.message);
                }
                setLoading(false);
                throw e
            }
        },
        [fetchUpdate, toast, url]
    );

    return { call, loading };
}

/*** DETAILS LOADING ***/

async function genericLoad(setFunction, retrieveFunction) {
    setFunction({ data: null, loading: true, error: null });
    try {
        let response = await retrieveFunction();
        setFunction({ data: response, loading: false, error: null });
    } catch (e) {
        setFunction({ data: null, loading: false, error: e.message });
    }
}

function initInfo() {
    return { data: null, loading: false, error: null };
}

/**
 * Hook to get all the relevant device data to be used in the details page.
 * @param {number} id - device ID
 */
export function useDevice(id) {
    const [details, setDetails] = useState(initInfo);
    const [twin, setTwin] = useState(initInfo);
    const [dwelling, setDwelling] = useState(initInfo);
    const [equipment, setEquipment] = useState(initInfo);
    const [channels, setChannels] = useState(initInfo);
    const [monitor, setMonitor] = useState(initInfo);
    const [controllers, setControllers] = useState(initInfo);
    const [hardwareState, setHardwareState] = useState(initInfo);
    const [currentTelemetry, setCurrentTelemetry] = useState(initInfo);
    const [havenScore, setHavenScore] = useState(initInfo);
    const [offsets, setOffsets] = useState(initInfo);

    function loadDetails() {
        genericLoad(setDetails, () =>
            getDevice(id).then(details => {
                if (details["dwelling_id"]) {
                    loadDwelling(details["dwelling_id"]);
                }
                if (details["equipment_id"]) {
                    loadEquipment(details["equipment_id"]);
                }
                if (details["type"] === "cac") {
                    loadChannels();
                }
                if (details["type"] === "cam") {
                    //loadControllers(details["cac_device_ids"]);
                    loadHavenScore();
                    loadOffsets();
                }
                if (details["cam_device_id"]) {
                    loadMonitor(details["cam_device_id"]);
                }

                return details;
            })
        );
    }

    function loadHardwareState() {
        genericLoad(setHardwareState, () => getDeviceHardwareState(id));
    }

    function loadCurrentTelemetry() {
        genericLoad(setCurrentTelemetry, () => getDeviceCurrentTelemetry(id));
    }

    function loadHavenScore() {
        genericLoad(setHavenScore, () => getDeviceHavenScore(id));
    }

    function loadControllers(controllerIds) {
        genericLoad(setControllers, () => {
            const controllers = [];
            for (let controllerId of controllerIds) {
                getDevice(controllerId).then(device => {
                    controllers.push(device);
                });
            }
            return controllers;
        });
    }

    function loadDwelling(dwellingId) {
        genericLoad(setDwelling, () => getDwelling(dwellingId));
    }

    function loadEquipment(equipmentId) {
        genericLoad(setEquipment, () => getEquipment(equipmentId));
    }

    function loadTwin() {
        genericLoad(setTwin, () => getDeviceTwin(id));
    }

    function loadOffsets() {
        genericLoad(setOffsets, () => getDeviceOffsets(id));
    }

    function loadChannels() {
        genericLoad(setChannels, () =>
            getDeviceChannels(id).then(async channels => {
                // Get channel equipment
                for (let channel of channels) {
                    if (channel.equipment_id) {
                        let equipment = await getEquipment(channel.equipment_id);
                        channels[channel.index]["equipment"] = equipment;
                    }
                }
                return channels;
            })
        );
    }

    function loadMonitor(monitorId) {
        genericLoad(setMonitor, () => getDevice(monitorId));
    }

    useEffect(() => {
        // TODO: figure out why this is triggering twice??
        loadDetails();
        loadTwin();
        loadHardwareState();
        loadCurrentTelemetry();
    }, []);

    return { details, dwelling, twin, channels, monitor, equipment, controllers, hardwareState, currentTelemetry, havenScore, offsets };
}

/**
 * Hook to get all the relevant user data to be used in the details page.
 * @param {number} id - user ID
 */
export function useUser(id) {
    const [details, setDetails] = useState(initInfo());
    const [profile, setProfile] = useState(initInfo());
    const [permissions, setPermissions] = useState(initInfo());
    const [roles, setRoles] = useState(initInfo());
    const [devices, setDevices] = useState(initInfo());
    const [filters, setFilters] = useState(initInfo());
    const [ecosense, setEcosense] = useState(initInfo());

    function loadDetails() {
        genericLoad(setDetails, () => getUser(id));
    }

    function loadProfile() {
        genericLoad(setProfile, () => getUserProfile(id));
    }

    function loadPermissions() {
        genericLoad(setPermissions, () =>
            getUserPermissions(id).then(permissions => {
                loadDevices(permissions);
                return permissions;
            })
        );
    }

    function loadRoles() {
        genericLoad(setRoles, () => getUserRoles(id));
    }

    function loadDevices(permissions) {
        // First, get ids of dwellings
        let dwellingIds = [];
        for (let permission of permissions) {
            if (!dwellingIds.includes(permission.id)) {
                dwellingIds.push(permission.id);
            }
        }

        // Get dwelling contents for each dwelling id found
        let calls = [];
        for (let dwellingId of dwellingIds) {
            let call = getDwellingContents(dwellingId);
            calls.push(call);
        }

        // Asynchonously get list of devices and equipments from each dwelling content
        genericLoad(setDevices, () =>
            Promise.all(calls).then(contents => {
                let devices = [];
                let equipments = [];
                for (let content of contents) {
                    devices.push(...content["devices"]);
                    equipments.push(...content["equipment"]);

                }
                loadFilters(equipments);
                return devices;
            })
        );
    }

    function loadFilters(equipments) {
        // Retrieve filter ids from each equipment
        let filterIds = [];
        for (let equipment of equipments) {
            filterIds.push(...equipment.filters);
        }

        // Asynchronously get details of each filter given its ID
        let calls = [];
        for (let filterId of filterIds) {
            calls.push(getFilter(filterId));
        }
        genericLoad(setFilters, () => Promise.all(calls));
    }
    function loadEcosense() {
        genericLoad(setEcosense, () => getEcosenseDevices(id));
    }

    useEffect(() => {
        loadDetails();
        loadProfile();
        loadPermissions();
        loadRoles();
        loadEcosense();
    }, []);

    function refreshPermissions() {
        loadPermissions();
    }
    console.log("user Ecosense", ecosense)

    return { details, profile, permissions, roles, devices, filters, ecosense, refreshPermissions };
}

/**
 * Hook to get all the relevant dwelling data to be used in the details page.
 * @param {number} id - dwelling ID
 */
export function useDwelling(id) {
    const [details, setDetails] = useState(initInfo());
    const [profiles, setProfiles] = useState(initInfo());
    const [company, setCompany] = useState(initInfo());
    const [equipments, setEquipments] = useState(initInfo());
    const [permissions, setPermissions] = useState(initInfo());
    const [devices, setDevices] = useState(initInfo());
    const [ecosense, setEcosense] = useState(initInfo());
    const [automations, setAutomations] = useState(initInfo());

    function loadDetails() {
        genericLoad(setDetails, () =>
            getDwelling(id).then(details => {
                if (details.preferred_service_company_id) {
                    loadCompany(details.preferred_service_company_id);
                }
                return details;
            })
        );
    }

    function loadCompany(companyId) {
        genericLoad(setCompany, () => getCompany(companyId));
    }

    function loadPermissions() {
        genericLoad(setPermissions, () => getDwellingPermissions(id));
    }

    function loadDevices() {
        genericLoad(setDevices, () =>
            // Get devices by getting the dwelling contents first, then looking through its list of devices and equipments
            getDwellingContents(id).then(contents => {
                let devices = contents["devices"];
                let equipments = contents["equipment"];



                // Equipment list is part of the content, since there is no need to do another API call just return equipments, still set data through genericLoad() for consistency
                genericLoad(setEquipments, () => {
                    return equipments;
                });
                genericLoad(setEcosense, () => contents["ecosense_devices"]);
                // Load profiles based on zones
                const zones = getDwellingZones(devices, equipments);
                loadProfiles(zones);
                // Load automation details for each device
                // loadAutomations(devices); // old API
                // Finally return devices inside the dwelling
                return devices;
            })
        );
    }

    // This function simply retrieves zones from the list of devices and equipment that belong to the dwelling
    function getDwellingZones(devices, equipments) {
        let zones = [];
        for (let device of devices) {
            zones.push(device.zone);
        }
        for (let equipment of equipments) {
            zones.push(equipment.zone);
        }
        zones = [...new Set(zones)]; // remove duplicate zones by creating a set and converting it back to the list
        return zones;
    }

    function loadProfiles(zones) {
        let calls = [];
        for (let zone of zones) {
            calls.push(
                getDwellingProfile(id, zone)
                    .then(profile => {
                        // Set zone of profile to later easily determine which zone the profile belongs to
                        profile.zone = zone;
                        return profile;
                    })
                    .catch(error => {
                        // If there is an error code 5, it means profile is not found - just return null, any other error - throw an exception
                        if (error.code === 5) {
                            return null;
                        } else {
                            throw error;
                        }
                    })
            );
        }
        genericLoad(setProfiles, () => Promise.all(calls).then(profiles => profiles.filter(p => p !== null)));
    }

    async function loadAutomations(devices) {
        const results = [];
        // For each device get its automations
        for (const device of devices) {
            const deviceAutomations = await getDeviceAutomations(device.id);
            for (const deviceAutomation of deviceAutomations) {
                // For each automation get its automation details
                await getAutomationDetails(deviceAutomation["id"]).then(async automation => {
                    automation["zone"] = device["zone"];
                    automation["id"] = deviceAutomation["id"];
                    automation["cam_pcb"] = await getDevice(automation["cam_device_id"]).then(device => device["pcb_serial_number"]);
                    automation["dehumidifier_pcb"] = await getDevice(automation["dehumidifier_dehumidification_device_id"]).then(
                        device => device["pcb_serial_number"]
                    );
                    automation["damper_pcb"] = await getDevice(automation["damper_device_id"]).then(device => device["pcb_serial_number"]);
                    // add automation only if it has not been added to the list already
                    if (!results.find(a => a["id"] === automation["id"])) {
                        results.push(automation);
                    }
                });
            }
        }
        genericLoad(setAutomations, () => results);
    }

    useEffect(() => {
        loadDetails();
        loadPermissions();
        loadDevices();
    }, []);

    return { details, profiles, company, equipments, permissions, devices, automations, ecosense };
}

/**
 * Hook to get all the relevant company data to be used in the details page.
 * @param {number} id - company ID
 */
export function useCompany(id) {
    const [details, setDetails] = useState(initInfo());
    const [profile, setProfile] = useState(initInfo());
    const [dwellings, setDwellings] = useState(initInfo());
    const [roles, setRoles] = useState(initInfo());
    const [devices, setDevices] = useState(initInfo());
    const [ecosense, setEcosense] = useState(initInfo());
    const [invites, setInvites] = useState(initInfo());
    const [journey, setJourney] = useState(initInfo());
    const [journeyLog, setJourneyLog] = useState(initInfo());
    const [onboardingQuestionnaire, setOnboardingQuestionnaire] = useState(initInfo());


    function loadDetails() {
        genericLoad(setDetails, () => getCompany(id));
    }

    function loadProfile() {
        genericLoad(setProfile, () => getCompanyProfile(id));
    }

    function loadDwellings() {
        genericLoad(setDwellings, () =>
            getCompanyDwellings(id).then(dwellings => {
                loadDevices(dwellings);
                return dwellings;
            })
        );
    }

    function loadDevices(dwellings) {
        let calls = [];
        for (let dwelling of dwellings) {
            calls.push(getDwellingContents(dwelling.id).then(contents => { return { devices: contents["devices"], ecosense_devices: contents["ecosense_devices"] } }));
        }

        // TODO make sure the list does not have to be flattened after the promise. Also makre sure there are not duplciates.
        genericLoad(setDevices, () => Promise.all(calls).then(data => {
            const devices = data.map(d => d.devices).flat();
            console.log(devices)
            setEcosense({ data: data.map(d => d.ecosense_devices).flat(), loading: false, error: null });
            return devices
        }));
    }

    function loadRoles() {
        genericLoad(setRoles, () => getCompanyRoles(id));
    }

    function loadInvites() {
        genericLoad(setInvites, () => getCompanyInvites(id));
    }

    function loadJourney() {
        genericLoad(setJourney, () => getCompanyJourney(id));
    }

    function loadJourneyLog() {
        genericLoad(setJourneyLog, () => getCompanyJourneyLog(id));
    }
    function loadOnboardingQuestionnaire() {
        genericLoad(setOnboardingQuestionnaire, () => getCompanyQuestionnaire(id));
    }

    useEffect(() => {
        loadDetails();
        loadProfile();
        loadDwellings();
        loadRoles();
        loadInvites();
        loadJourney();
        loadJourneyLog();
        loadOnboardingQuestionnaire();
    }, []);
    console.log(ecosense)
    return { details, profile, dwellings, roles, devices, invites, journey, journeyLog, onboardingQuestionnaire, ecosense };
}

/**
 * Hook to get all the relevant equipment data to be used in the details page.
 * @param {number} id - equipment ID
 */
export function useEquipment(id) {
    const [details, setDetails] = useState(initInfo());
    const [dwelling, setDwelling] = useState(initInfo());
    const [filters, setFilters] = useState(initInfo());

    function loadDetails() {
        genericLoad(setDetails, () =>
            getEquipment(id).then(details => {
                if (details.dwelling_id) {
                    loadDwelling(details.dwelling_id);
                    loadFilters(details.dwelling_id);
                }
                return details;
            })
        );
    }

    function loadDwelling(dwellingId) {
        genericLoad(setDwelling, () => getDwelling(dwellingId));
    }

    async function loadFilters(dwellingId) {
        // To get filters of an equipment, it has to be done through calling contents of the corresponding dwelling
        // TODO: add a server endpoint to get filters in one call

        // First, dwelling content has to be loaded to get list of all its equipments
        let contents = await getDwellingContents(dwellingId);

        // Only select the current equipment from the list
        let equipment = contents["equipment"].find(e => e.id === +id);

        // Equipment objects that come from getDwellingContents() also contain ids of their filters
        let filterIds = equipment ? equipment["filters"] : [];

        // Get details of each filter
        let calls = [];
        for (let filterId of filterIds) {
            calls.push(getFilter(filterId));
        }
        genericLoad(setFilters, () => Promise.all(calls));
    }

    useEffect(() => {
        loadDetails();
    }, []);

    return { details, dwelling, filters };
}

/**
 * Hook to get all the relevant filter data to be used in the details page.
 * @param {number} id - filter ID
 */
export function useFilter(id) {
    const [details, setDetails] = useState(initInfo());
    const [equipment, setEquipment] = useState(initInfo());

    function loadDetails() {
        genericLoad(setDetails, () =>
            getFilter(id).then(details => {
                if (details.equipment_id) {
                    loadEquipment(details.equipment_id);
                }
                return details;
            })
        );
    }

    function loadEquipment(equipmentId) {
        genericLoad(setEquipment, () => getEquipment(equipmentId));
    }

    useEffect(() => {
        loadDetails();
    }, []);

    return { details, equipment };
}

/*** SIMULATION ***/

/**
 * Simulate Haven Score given the device id and interval boundaries. Simulation logic is on the haven server and depends on the corresponding telemetry for the given interval.
 */
export function useHavenScoreSimulation() {
    const [scoreProgress, setProgress] = useState(null); // it's a float where 0 represents 0%, 1 represents 100%
    const [errorHS, setError] = useState(null);

    async function simulateHavenScore(deviceId, startTime, endTime) {
        // Reset error
        setError(null);

        // Split interval into 24 hour periods as that's the maximum interval the server supports
        const days = splitInterval(startTime, endTime);

        // At least 2 timestamps are needed to define the interval
        if (days.length < 2) {
            setProgress(null);
            return;
        }

        // Reset progress
        setProgress(0);

        // Set up the empty queue and it's maximum length
        let queueMaxLength = 7; // this is the limit of calls that can be done asynchronously
        let queue = [];

        // Get telemetry for each interval asynchronously
        let i = 0;
        while (true) {
            // When the queue is not full and there are still more days to get, just push an API call to the queue
            if (i < days.length - 1 && queue.length < queueMaxLength) {
                const start = date.format(days[i], "YYYY-MM-DD HH:mm:ss", false);
                const end = date.format(days[i + 1], "YYYY-MM-DD HH:mm:ss", false);
                const url = `${apiUrl}/device/${deviceId}/haven_score`;
                const body = {
                    start_time: start,
                    end_time: end,
                };
                const call = fetchPost(url, body);
                queue.push(call);
                i++;
            }

            // When the queue is full or the last interval has been pushed to the queue, call all the calls in the queue
            if (queue.length === queueMaxLength || i === days.length - 1) {
                await Promise.all(queue)
                    .then(result => {
                        // When all calls are finished, empty the queue and update the progress
                        queue = [];
                        setProgress(i / (days.length - 1));
                    })
                    .catch(error => {
                        setError(error.message);
                    });
            }

            // When all days are pushed to the queue, exit the loop
            if (i === days.length - 1) {
                break;
            }
        }
    }

    return { scoreProgress, simulateHavenScore, errorHS };
}

/**
 * Simulate device telemetry given the device id, interval boundaries and the config representing telemetry behaviour. Simulation logic is on the haven server.
 */
export function useTelemetrySimulation() {
    const [telemetryProgress, setProgress] = useState(null);

    async function simulateTelemetry(deviceId, startTime, endTime, config) {
        // Split interval into 24 hour periods as that's the maximum interval the server supports
        const days = splitInterval(startTime, endTime);
        // Reset progress
        setProgress(0);

        // Set up the empty queue and it's maximum length
        let queueMaxLength = 7; // this is the limit of calls that can be done asynchronously
        let queue = [];

        // Simulate telemetry for each period asynchronously
        let i = 0;
        for (let day of days) {
            // When the queue is not full and there are still more days to get, just push an API call to the queue
            if (i < days.length - 1 && queue.length < queueMaxLength) {
                const start = days[i]
                const end = days[i + 1]
                const url = `${apiUrl}/device/${deviceId}/simulation`;

                let body = { ...config };
                await fetchPost(url, body);

                // Update progress
                i++;
                setProgress(i / (days.length - 1));
            }
        }

        setProgress(1);
    }

    return { telemetryProgress, simulateTelemetry };
}
