import {
    Radio,
    RadioGroup,
    Text,
    VStack,
} from "@chakra-ui/react"
import Emphasis from "components/emphasis"
import Muted from "components/muted"
import { parseMeasurement } from "pages/util"
import { useRef, useState } from "react"
import { AutomationInput } from "types"
import { useSkipFirstRender } from "./dwelling-automations"
import EmptyState from "./empty-state"
import { InputSetter } from "./rule"
import useIsDisabled from "./useIsDisabled"

const OutdoorAir = ({ input, automationId, loading, set }: {
    input?: AutomationInput
    automationId: number
    loading: boolean
    set: InputSetter
}) => {
    const measurement = input?.measurement
    const [factor, setFactor] = useState(`${input?.start_set_point}`)
    const touched = useRef(false)
    const parsedMeasurement = parseMeasurement(measurement || 'epa_aqi')
    const disabled = useIsDisabled(automationId)

    const _setFactor = (x) => {
        if (!touched.current) touched.current = true
        setFactor(x)
    }

    useSkipFirstRender(() => {
        if (!factor) return
        if (!input) return
        if (!touched.current) return
        set(inputs => inputs.map(_input => {
            if (input.id === _input.id) {
                return {
                    ..._input,
                    start_set_point: parseInt(factor),
                    stop_set_point: parseInt(factor),
                }
            }
            return _input
        }))
    }, [factor])

    if (!input) return (
        <EmptyState title='Outdoor air' />
    )

    return (
        <VStack
            spacing={4}
            alignItems='flex-start'
            w='100%'>
            <Muted>Activate equipment only if <Emphasis>{parsedMeasurement}</Emphasis> is better than</Muted>
            <RadioGroup isDisabled={loading || disabled} onChange={_setFactor} value={factor}>
                <VStack w='100%' alignItems='flex-start'>
                    <Radio value='1'>
                        <Text as='span' fontWeight='semibold'>Fair </Text>
                        <Muted as='span' fontWeight='normal'>50</Muted>
                    </Radio>
                    <Radio value='2'>
                        <Text as='span' fontWeight='semibold'>Poor </Text>
                        <Muted as='span' fontWeight='normal'>100</Muted>
                    </Radio>
                </VStack>
            </RadioGroup>
        </VStack>
    )
}

export default OutdoorAir
