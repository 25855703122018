import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import EntitiesList from "./entities-list";

/**
 * Renders list of monitors and controllers in two tabs using EntitiesList for each of the tabs.
 */
export default function DevicesList({ devices, ecosense = [] }) {
    const monitors = devices.filter(device => device.type === "cam");
    const controllers = devices.filter(device => device.type === "cac");
    console.log(monitors, controllers, ecosense)
    return (
        (monitors?.length > 0 || controllers?.length > 0 || ecosense?.length > 0) ? (
            <Tabs isFitted variant="enclosed" display="flex" flexDirection="column" h="100%">
                <TabList>
                    {monitors.length > 0 && <Tab>Monitors</Tab>}
                    {controllers.length > 0 && <Tab>Controllers</Tab>}
                    {ecosense.length > 0 && <Tab>Third-party Monitors</Tab>}
                </TabList>
                <TabPanels
                // flex={1}
                >
                    {monitors.length > 0 && <TabPanel
                        p={0}
                        h="100%"
                    >
                        <EntitiesList
                            list={monitors}
                            openUrl="devices"
                            columns={["pcb_serial_number", "plastic_serial_number", "zone"]}
                        ></EntitiesList>
                    </TabPanel>}
                    {controllers.length > 0 && <TabPanel p={0} h="100%">
                        <EntitiesList
                            list={controllers}
                            openUrl="devices"
                            columns={["pcb_serial_number", "plastic_serial_number", "zone"]}
                        ></EntitiesList>
                    </TabPanel>}
                    {(ecosense.length > 0) && (<TabPanel p={0} h="100%">
                        <EntitiesList
                            list={ecosense}
                            openUrl={null}
                            columns={Object.keys(ecosense[0])}
                        ></EntitiesList>
                    </TabPanel>)}
                </TabPanels>
            </Tabs>
        ) : <span>User has no devices</span>);
}
