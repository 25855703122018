import { HStack, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react"
import { usePost } from "api/api-hooks"
import Disabled from "components/disabled"
import FormLabel from "components/label"
import { humanize, useDisabled, useMuted } from "pages/util"
import { memo, useState } from "react"
import { Automation, SaveAutomation } from "types"
import Header from "./header"
import Relationships from "./relationships"
import Rule from "./rule"
import { AutomationRule } from "./rulesStore"
import Supertitle from "./supertitle"
import { HavenRedButton } from "components/buttons"
import { set } from "date-fns"

// const SmallTitle = props => <Text as='span' fontSize='xs' fontWeight='normal' {...props} />

const SingleAutomation = memo(({ automation, rules, devices, equipmentDetails, refetch }: {
    automation: Automation
    rules: AutomationRule[]
    devices: any[] | null
    equipmentDetails: any[] | null
    refetch: () => Promise<void>
}) => {
    const [loading, setLoading] = useState(false)
    const automationApi = usePost(`automation/${automation.id}`)
    const muted = useMuted()
    const disabledBlue = useColorModeValue('blue.100', 'blue.800')
    const disabledColor = useDisabled()
    const subtitleProps = { ...(automation.enabled ? {} : { color: disabledColor }) }

    if (!automation?.id) return null
    if (!rules?.length) return null

    const save: SaveAutomation = async fn => {
        const data = fn(automation)
        setLoading(true)
        try {
            await automationApi.call(data)
            await refetch()
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }
    const disableAutomation = async (event) => {

        const data = { enabled: false }
        setLoading(true)
        try {
            await automationApi.call(data)
            await refetch()
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }
    return (
        <VStack
            spacing={4}
            alignItems='flex-start'
        >
            <HStack
                justifyContent='space-between'
                alignItems='flex-start'
                w='100%'
            >
                <Header>
                    <Supertitle color={automation.enabled ? 'blue.500' : disabledBlue}>
                        Automation
                    </Supertitle>
                    <HStack spacing={4}>
                        <Stack spacing={0}>
                            <Text color={muted} as='span' fontSize='xs' fontWeight='normal' {...subtitleProps}>
                                ID
                            </Text>
                            <Text {...subtitleProps}>
                                {automation.id}
                            </Text>
                        </Stack>
                        <Stack spacing={0}>
                            <Text color={muted} as='span' fontSize='xs' fontWeight='normal' {...subtitleProps}>
                                Template
                            </Text>
                            <Text {...subtitleProps}>
                                {humanize(automation.template)}
                            </Text>
                        </Stack>
                        <Stack spacing={0}>
                            <Text color={muted} as='span' fontSize='xs' fontWeight='normal' {...subtitleProps}>
                                Zone
                            </Text>
                            <Text {...subtitleProps}>
                                {humanize(automation.zone)}
                            </Text>
                        </Stack>
                    </HStack>
                </Header>
                <HStack>
                    {automation.enabled ? (<>
                        <FormLabel>Enabled</FormLabel>
                        <HavenRedButton
                            label="Disable"
                            onClick={disableAutomation}
                        /></>
                    ) : (
                        <FormLabel><Disabled>Disabled</Disabled></FormLabel>
                    )}

                </HStack>
            </HStack>
            <HStack
                spacing={4}
                width='100%'
                flexWrap='wrap'
                alignItems='flex-start'>
                <VStack>
                    {rules.map(({ inputs, schedules, trigger, equipment }) => (
                        <Rule
                            key={trigger.id}
                            loading={loading}
                            save={save}
                            equipment={equipment}
                            automationId={automation.id}
                            inputs={inputs}
                            schedules={schedules}
                            trigger={trigger}
                            refetch={refetch}
                        />
                    ))}
                </VStack>
                <Relationships automationId={automation.id} devices={devices} equipment={equipmentDetails} />
            </HStack>
        </VStack>
    )
})

export default SingleAutomation
