export type JourneyState =
    "created" |
    "sign_up_stuck" |
    "pup_purchased" |
    "pup_received" |
    "pup_stuck" |
    "pup_install" |
    "customer_install_stuck" |
    "customer_install" |
    "repeat_install_stuck" |
    "repeat_install"

export type Measurement =
    'pm25' |
    'voc' |
    'temperature' |
    'humidity' |
    'dew_point' |
    'epa_aqi'

export type ScheduleInterval =
    "always_off" |
    "on_5_off_55" |
    "on_5_off_25" |
    "on_10_off_20" |
    "on_20_off_20" |
    "on_20_off_10" |
    "always_on"

export enum ScheduleIntervals {
    "always_off" = "always_off",
    "on_5_off_55" = "on_5_off_55",
    "on_5_off_25" = "on_5_off_25",
    "on_10_off_20" = "on_10_off_20",
    "on_20_off_20" = "on_20_off_20",
    "on_20_off_10" = "on_20_off_10",
    "always_on" = "always_on",
}

export interface AutomationInput {
    id: number
    type: string
    currently_triggered: boolean
    device_id: number
    measurement: Measurement
    rising: boolean
    start_set_point: number
    stop_set_point: number
    set_point_unit: 'celsius' | 'fahrenheit'
}

export interface AutomationSchedule {
    id: number
    type: string
    currently_triggered: boolean
    weekday_awake_interval: ScheduleInterval
    weekday_sleep_interval: ScheduleInterval
    weekend_awake_interval: ScheduleInterval
    weekend_sleep_interval: ScheduleInterval
    weekend_start_time: string
    weekend_stop_time: string
    weekday_start_time: string
    weekday_stop_time: string
}


export interface AutomationOutput {
    id: number
    type: string
    currently_triggered: boolean
    device_id: number
    terminal: number
    active_high: boolean
    equipment_id: number
    equipment_component: number
}

export interface AutomationTrigger {
    id: number
    enabled: boolean
    filtration: boolean
    ventilation: boolean
    humidity: boolean
    inputs: number[]
    outputs: number[]
}

export type Template = 'ventilating_dehumidifer_one_controller'

export interface Automation {
    id: number
    zone: string
    create_timestamp: string // date string
    dwelling_id: number
    enabled: boolean
    active: boolean
    template: Template
    filtration_override_on: boolean
    filtration_override_timeout: string | null // date string
    ventilation_override_on: boolean
    ventilation_override_timeout: string | null // date string
    humidity_override_on: boolean
    humidity_override_timeout: null
    inputs: (AutomationInput | AutomationSchedule)[]
    outputs: AutomationOutput[]
    rules: AutomationTrigger[]
}

export interface Equipment {
    id: number
    dwelling_id: number
    name: string
    install_timestamp: string
    type: number
    zone: string
    zone_size: string
    heating: boolean
    cooling: boolean
    duct_type: string
    duct_shape: string
    duct_material: string
    duct_width: number
    duct_height: number
    normally_closed: boolean
}

export interface Device {
    cac_device_ids: number[]
    cam_device_id?: number
    dwelling_id: number
    equipment_id?: number
    hub_device_id: string
    hub_id: string
    id: number
    install_timestamp: string
    install_type: string
    name: string
    pcb_serial_number: string
    plastic_serial_number: string
    type: string
    zone: string
    zone_size: number
}

type CB = (x: Automation) => Partial<Automation>
export type SaveAutomation = (callback: CB) => Promise<void>

export interface EquipmentWithOutputAndTriggers extends Equipment {
    output?: AutomationOutput
    triggers?: AutomationTrigger[]
}
